<template>
    <div class="WrapPage">
        <header class="Header-Shopingmall">
            <div class="WrapTitlePage">
                <span class="TitlePage">
                    Dashboard
                </span>
                <span class="date">
                    {{ currentTime }}{{ " " + formatDateToday }}
                </span>
            </div>
            <div class="avatar">
                <InfoUserDialog />
            </div>
        </header>
        <!-- End Header -->
        <ListCardForDashboardShopingmall></ListCardForDashboardShopingmall>
        <!-- End Card -->
        <section class="FootFall mt-40" >
            <div class="ChartFootFall">
                <span class="titleChart">{{ $t("dashboardShopingMall.titleForGateCard").toUpperCase() }}</span>
                <div class="wrapChartLine">
                    <LineChartForDashboardShopingmall></LineChartForDashboardShopingmall>
                    <div class="HiddenTrial">
                    </div>
                </div>
                <div class="wrapNote mt-8">
                    <div class="note mr-8"> <div class="ColorNoteToday mr-8"></div> <span>{{ $t("Report.Today") }}</span></div>
                    <div class="note mr-8"> <div class="ColorNoteYesterday mr-8"></div> <span>{{ $t("Report.Yesterday") }}</span></div>
                </div>
            </div>
            <div class="Tablekpi">
                <TableKPI class=" tableKPIStyle" />
            </div>            
        </section>
        <!-- Heatmap layout -->
        <section class="heatmapLayout mt-40">
            <HeatmapLayoutForDashboardShopingMall></HeatmapLayoutForDashboardShopingMall>
        </section>

        <!-- BÁO CÁO KHÁCH HÀNG -->
        <section class="ReportCustomerTables mt-40">
            <span class="titleTables">
                {{ $t("dashboardShopingMall.reportCustomer").toUpperCase() }}
            </span>
            <ReportCustomer class="mt-24"/>
        </section>
    </div>
</template>
<script>
import * as moment from "moment-timezone";
import "moment/locale/vi";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

import { InfoUserDialog } from "@/components";
import {
    ReportCustomer,
    TableKPI,
    ListCardForDashboardShopingmall,
    LineChartForDashboardShopingmall,
    HeatmapLayoutForDashboardShopingMall,
} from "@/pages";
export default {
    name: "Dashboard-ShopingMall",
    components: {
       InfoUserDialog,
       ReportCustomer,
       TableKPI,
       ListCardForDashboardShopingmall,
       LineChartForDashboardShopingmall,
       HeatmapLayoutForDashboardShopingMall
    },
    computed: {
        currentTime: function() {
            return this.date.locale("en").format("hh:mm A");
        },
    },
    data() {
        return {
            date: moment(),
            formatDateToday: moment().format("DD/MM/YYYY"),
        }
    }
}
</script>
<style lang="scss">
.WrapPage {
    .Header-Shopingmall {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .WrapTitlePage {
            .TitlePage {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 28px;
                line-height: 32px;
                color: #000000;
            }
            .date {
                font-family: 'Roboto';
                font-weight: 450;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                }
            }
    }
    .wrapCard {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        row-gap: 0;
        column-gap: 16px;
    }
    .FootFall {
        display: grid;
        width: 100%;
        grid-template-columns: 40% 1fr;
        row-gap: 0;
        column-gap: 24px;
        .ChartFootFall {
            background-color: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
            height: 100%;
            padding: 24px;
            .titleChart {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1F1F1F;
            }
            .wrapChartLine {
                padding-top: 12px;
                height: 388px;
                overflow: hidden;
                position: relative;
                .HiddenTrial {
                    width: 35px;
                    height: 0px;
                    background-color: #FFFFFF;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                }
            }
            .wrapNote {
                flex-wrap: wrap;
                justify-content: center;
                display: flex;
                width: 100%;
                .note {
                   display: flex;
                   align-items: center;
                   margin-top: 8px;
                    .ColorNoteToday {
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        background-color: #D81F41;
                    }
                    .ColorNoteYesterday {
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        background-color: #004961;
                    }
                    .ColorNoteYesterday1 {
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        background-color: #68768A;
                    }
                    .ColorNoteYesterday2 {
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        background-color: #00CC6A;
                    }
                    .ColorNoteYesterday3 {
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        background-color: #FFB900;
                    }
                     span {
                        max-width: 108px;
                        min-width: 88px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                
            }
        }
        .Tablekpi {
            background-color: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
            padding: 24px;
            position: relative;
            .Wraptitle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .titleTable {
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #1F1F1F;
                }
                .WrapNumber {
                    display: flex;
                    align-items: center;
                    .Back {
                        height: 24px;
                        cursor: pointer;
                    }
                    .Number {
                        height: 24px;
                        width: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                    .Next {
                        height: 24px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .heatmapLayout {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        padding: 24px;
        .wrapHeaderHeatmap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .titleHeatmap{ 
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1F1F1F;
            }
            .custom-buttonHeatmap {
                color:#276EFF;
                border-color: #276EFF;
                .wrapOption {
                    width: 100%;
                    right: 0;
                    top: 44px;
                    .Option {
                        width: auto !important;
                    }
                }
            }
        }
       .heatmaplayout {
           width :100%;
           img {
               width :100%;
           }
       }
    }
    .ReportCustomerTables {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        padding: 24px;
        .titleTables {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1F1F1F;
        }
    }
}
</style>
