<template>
    <div class="WrapReportShopingmall">
        <header class="Header-Shopingmall">
            <span class="TitlePage">
                Report
            </span>
            <div class="avatar">
                <InfoUserDialog />
            </div>
        </header>
        <!-- End Header -->
        <section 
            class="TimeSelect mt-40"
            v-bind:class="{ fixedHeaderStyle: fixedHeader }"
        >
            <div class="Wraptime">
                <span class="titleTime mr-16">{{
                    $t("reportShopingMall.selectTime")
                }}</span>
                <TimeSelectShopingMall
                    @changeDateRange="changeDateRange"
                    @changeDateRangeString="changeDateRangeString"
                    @changeCompareText="changeCompareText"
                    @changeListDateRange="changeListDateRange"
                    @changeCompareType="changeCompareType"
                />
            </div>
            <div class="ExportReport" @click="exportDataToExcel">
                <span class="material-icons mr-8"> file_download </span>
                {{ $t("reportShopingMall.DownloadReport") }}
            </div>
        </section>
        <!-- End Time Select -->
        <ListCardForReportShopingMall 
            :dateRange="dateRange"
            :compareText="compareText"
            :compareType="compareType" 
        />

        <SectionTwoReport :dateRange="dateRange"/>
        <!-- End Footfall -->
        <SectionThreeReport :compareType="compareType" :dateRange="dateRange"/>
    </div>
</template>
<script>
import { Api } from "@/api/index";

import { InfoUserDialog } from "@/components";

import {
    TimeSelectShopingMall,
    ListCardForReportShopingMall,
    SectionTwoReport,
    SectionThreeReport
} from "@/pages";
import * as moment from "moment-timezone";


const exportExcelShoppingmall = Api.get("exportExcelShoppingmall")

export default {
    name: "Report-ShopingMall",
    components: {
        InfoUserDialog,
        TimeSelectShopingMall,
        ListCardForReportShopingMall,
        SectionTwoReport,
        SectionThreeReport
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("beforeunload", this.handleUnload);
        
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.handleUnload);
    },
    destroyed() {
        localStorage.setItem("hiddenTrial2RefreshInReport", "false")
        window.removeEventListener("scroll", this.handleScroll);
    },
    data() {
        let self = this
        return {
            showing: false,
            
            ShowOption: false,
            fixedHeader: false,
            compareText: self.$t("dashboard.ThePreviousSevenDays"),
            tableKPIData: [],
            dateRange: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X")
            },
            dateRangeForTableKPI: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X")
            },
            isAverage: false,
            heatmapData: [],
            compareType: "normal",
        };
    },
    methods: {
        exportDataToExcel() {
            let lang = localStorage.getItem("lang");
            let timestamp = Math.floor(Date.now() / 1000)
            exportExcelShoppingmall.get(this.dateRange.dateStart, this.dateRange.dateEnd, lang, timestamp).then(response => {
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    })
                );
                let fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.href = fileURL;
                let fileName = 
                    moment.unix(this.dateRange.dateStart).format("DD/MM/YYYY").replaceAll("/", "") +
                    "-" +
                    moment.unix(this.dateRange.dateEnd).format("DD/MM/YYYY").replaceAll("/", "") + "-" +
                    localStorage.email.split("@")[0] + "-" +
                    "data.xlsx"
                fileLink.download = fileName;

                fileLink.click();
            }).catch(error => {
                this.$swal(this.$t("CustomerResponse.PC0003"), "", "warning");
            })
        },
        handleScroll(event) {
            if (window.scrollY > 200) {
                this.fixedHeader = true;
            } else {
                this.fixedHeader = false;
            }
        },
        handleUnload(event) {
            localStorage.setItem("hiddenTrial2RefreshInReport", "true")
        },
        changeDateRange(val) {
            this.dateRange = val
            this.dateRangeForTableKPI = val
            
        },
        changeCompareText(val) {
            this.compareText = val
        },
        changeCompareType(val) {
            this.compareType = val
        },
        changeListDateRange(val) {
            this.listDateRange = val
        },
    }
};
</script>
<style lang="scss">
.fixedHeaderStyle {
    position: fixed;
    top: -40px !important;
    left: 120px;
    width: calc(100% - 160px) !important;
    z-index: 9;
}
.WrapReportShopingmall {
    .Header-Shopingmall {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .TitlePage {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 28px;
            line-height: 32px;
            color: #000000;
        }
    }
    .TimeSelect {
        height: 80px;
        display: flex;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 2px 16px rgb(0 0 0 / 5%);
        justify-content: space-between;
        align-items: center;
        padding: 18px 24px;
        .Wraptime {
            display: flex;
            align-items: center;
        }
        .titleTime {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
        }
        .Btn-TimeSelect {
            background: #ffffff;
            border: 1px solid #434343;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px 16px;
            display: flex;
            align-items: center;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #434343;
            position: relative;
            cursor: pointer;
        }
        .ExportReport {
            background: #ffffff;
            border: 1px solid #4f7dff;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px 16px;
            display: flex;
            align-items: center;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #4f7dff;
            cursor: pointer;
        }
        .bbt {
            border-bottom: 1px solid #d9d9d9;
        }
    }
    
}
</style>