<template>
    <div class="detail-report-main-container" ref="content">
        <div class="detail-select-container">
            <!-- Content 1 -->
            <div class="detail-report-title">
                <div class="TitleDashboard">
                    Dashboard
                    <p class="current-time">
                        {{ currentTime }} {{ dayText }}{{ formatDateToday }}
                    </p>
                </div>
            </div>
            <!-- select area -->
            <div class="Detail-search-noti-info">
                <div class="avatar">
                    <InfoUserDialog />
                </div>
            </div>
        </div>
        <div class="ChooseStore mt-40">
           {{choosenGroupName}}
           <span class="material-icons ml-8">arrow_drop_down</span>
            <div class="wrapOptionTime">
                <div
                    class="Option"
                    v-for="group in listGroup"
                    :key="group.id"
                    @click="changeGroup(group)"
                >
                    {{group.name}}
                </div>
            </div>
        </div>
        <!-- Funnel Chart & Overview -->
        <div class="WrapOverView mt-40">
            <div class="FunnelChart">
                <FunnelChart
                    :chartData="chartDataFunnel"
                    :chartLeftMargin="chartLeftMargin"
                ></FunnelChart>
                <div class="HiddenTrial">
                </div>

            </div>
            <div class="ValueOverview">
                <CardRetailStore v-for="card in listDataCard" :key="card.title"
                    :title="card.title"
                    :total="card.total"
                    :percentage="card.percentage"
                    :icon="card.icon"
                    compareText="dashboard.ComparedToYesterday"    
                />
            </div>
        </div>
        <!-- Line chart & Heatmap table -->
        <div class="WrapChartTable mt-40">
            
            <div class="LineChart">
                <div class="HeaderLineChart ">
                    <div class="TitleLineChart">
                        {{ $t("dashboardShopingMall.titleForGateCard").toUpperCase() }}
                        </div>
                        <div
                            class="Btn-LineChart"
                            @click="chooseLineChartOption()"
                            v-click-outside="handleClickOutSideLineChart"
                        >
                            {{ linechartOption }}
                            <span class="material-icons"
                            >arrow_drop_down</span
                            >
                            <div
                                ref="wrapDayOption"
                                class="wrapOption"
                            >
                                <div
                                    class="Option"
                                    @click="changeOptionLineChart('Today')"
                                >
                                    {{ $t("Report.Today") }}
                                </div>
                                <div
                                    class="Option"
                                    @click="changeOptionLineChart('TheLastSevenDays')"
                                >
                                    {{ $t("Report.TheLastSevenDays") }}
                                </div>
                            </div>
                        </div>
                </div>
                <LineChartForDashboardRetailDay 
                    :maxNumber="maxNumber"
                    :rawData="dataGateTrending"
                    :listColorToUse="listColor"
                    v-if="linechartOption==$t('Report.Today')"/>
                <LineChartForDashboardRetailWeek
                    :maxNumber="maxNumber"
                    :rawData="dataGateTrending"
                    :listColorToUse="listColor"
                    
                    v-if="linechartOption==$t('Report.TheLastSevenDays')"/>
                <!-- <div class="HiddenTrial"></div> -->
                <div class="Bottom">
                    <div class="today">
                        <div class="ColorToday mr-8"></div>
                        {{legendName[0]}}
                    </div>
                    <div class="yesterday">
                        <div class="ColorYesterday mr-8"></div>
                        {{legendName[1]}}
                    </div>
                </div>
            </div>
            <div class="HeatmapTable">
                <KPIRetailStore retailStore="retailStore"/>
            </div>
        </div>
        <HeatmapComponentForRetail class="mt-40"></HeatmapComponentForRetail>
        <!-- Customer report -->
        <div class="WrapBigTable mt-40">
            <span class="title">
                {{$t("dashboardShopingMall.reportCustomer").toUpperCase()}} 
            </span>
            <ReportCustomer :groupName="choosenGroupNameRaw" retail="true" class="mt-16" /> 
        </div>
    </div>
</template>

<script>
import { 
    CardRetailStore, 
    LineChartForDashboardRetailDay, 
    KPIRetailStore, 
    ReportCustomer, 
    FunnelChart, 
    LineChartForDashboardRetailWeek, 
    HeatmapComponentForRetail 
} from "@/pages";
import { InfoUserDialog } from "@/components";
 
import * as moment from "moment-timezone";
import "vue2-datepicker/index.css";
import "moment/locale/vi";
import { Api } from "@/api/index";
import utils from "@/utils";


const getListGroupV2 = Api.get("getListGroupV2")
const overviewDashboardRetail = Api.get("overviewDashboardRetail")
const trendingDashboardRetail = Api.get("trendingDashboardRetail")

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

export default {
    name: "detail-report",
    components: {
        InfoUserDialog,
        CardRetailStore,
        LineChartForDashboardRetailDay,
        KPIRetailStore,
        ReportCustomer,
        FunnelChart,
        LineChartForDashboardRetailWeek,
        HeatmapComponentForRetail
    },
    mounted() {
        if (localStorage.lang == "en") {
            this.dayText = " "
        }
        this.getAPIOverviewData()
        this.getAPITrendingDashboard()
        getListGroupV2.get().then(response => {
            this.listGroup = response.data.listResponse
            this.listGroup.unshift({"id":"", "name":this.$t("dashboard.SumOfAllShop")})
        })
        
    },
    methods: {
        changeGroup(group) {
            this.choosenGroupName = group.name
            
            if (group.name == this.$t("dashboard.SumOfAllShop")) {
                this.choosenGroupNameRaw = ""
            } else {
                this.choosenGroupNameRaw = group.id
            }
            
            this.choosenGroupID = group.id
            this.getAPIOverviewData()
            if (this.linechartOption == this.$t("Report.TheLastSevenDays")) {
                this.getAPITrendingDashboard("sevenDaysAgo")
            } else {
                this.getAPITrendingDashboard("today")
            }
        },

        settingChartLeftMargin(way) {
            if (localStorage.lang == "en") {
                if (way >= 10000) {
                    this.chartLeftMargin = "-30"
                } else if (way > 1000 && way < 10000) {
                    this.chartLeftMargin = "-30"
                } else if (way > 100 && way < 1000) {
                    this.chartLeftMargin = "-25"
                } else if (way >= 10 && way < 100) {
                    this.chartLeftMargin = "-20"
                } else if (way < 10) {
                    this.chartLeftMargin = "-15"
                }
            } else {
                if (way >= 10000) {
                    this.chartLeftMargin = "-60"
                } else if (way > 1000 && way < 10000) {
                    this.chartLeftMargin = "-55"
                } else if (way > 100 && way < 1000) {
                    this.chartLeftMargin = "-50"
                } else if (way >= 10 && way < 100) {
                    this.chartLeftMargin = "-44"
                } else if (way < 10) {
                    this.chartLeftMargin = "-35"
                }
            }
        },
        getAPIOverviewData() {
            overviewDashboardRetail.get(this.choosenGroupID).then(response => {
                this.listDataCard = []
                this.transformDataOverview(response.data.gate, this.$t("dashboard.Footfall"), response.data.gate_percentage)
                this.transformDataOverview(response.data.time_average, this.$t("dashboard.Dwelltime"), response.data.time_average_percentage)
                this.transformDataOverview(response.data.rate_gate, this.$t("dashboard.TurnInRate"), response.data.rate_gate_percentage)
                this.transformDataOverview(response.data.rate_shop, this.$t("dashboard.SaleConversionRate"), response.data.rate_shop_percentage)
                this.settingChartLeftMargin(response.data.way)
                
                this.chartDataFunnel = [
                    {
                        label: "",
                        value: "1460000"
                    },
                    {
                        label: this.$t("dashboard.Passby") + " (" + response.data.way.toLocaleString("en-US") + ")",
                        value: "930000"
                    },
                    {
                        label: this.$t("dashboard.Footfall") + " (" + response.data.gate.toLocaleString("en-US") + ")",
                        value: "540000"
                    },
                    {
                        label: this.$t("dashboard.Transactions") + " (" + response.data.shop.toLocaleString("en-US") + ")",
                        value: "210000"
                    }
                ]
            })
        },
        transformDataOverview(total, title, percentage) {
            let totalTransform = ""
            let percentageTransform = ""
            let iconTransform = ""

            if (title == this.$t("dashboard.Dwelltime")) {
                if (total == 100000001) {
                    total = 0
                }
                totalTransform = utils.transformTime(total)
            } else {
                if (total > 1000) {
                    totalTransform = total.toLocaleString('en-EN')
                } else {
                    totalTransform = total.toString()
                }
            }
            
            if (percentage > 1000) {
                percentageTransform = percentage.toLocaleString('en-EN')
            } else {
                percentageTransform = percentage.toString()    
            }
            if (percentage == 100000001) {
                percentageTransform = 0
            }
            if (percentage >= 0) {
                iconTransform = "arrow_upward"
            } else {
                iconTransform = "arrow_downward"
            }

            if (title == this.$t("dashboard.TurnInRate") || title == this.$t("dashboard.SaleConversionRate")) {
                totalTransform = totalTransform + "%"
            }

            this.listDataCard.push({
                "title": title,
                "total": totalTransform,
                "icon": iconTransform,
                "percentage": percentageTransform
            })
        },


        getMaxNumber(rawDataArray, rawDataArrayPrevious) {
            let maxNumberOfStatic = Math.max(...rawDataArray)
            let maxNumberOfStaticPrevious = Math.max(...rawDataArrayPrevious)
            if (maxNumberOfStatic > maxNumberOfStaticPrevious) {
                this.maxNumber = this.round5(maxNumberOfStatic + maxNumberOfStatic / 3)
            } else {
                this.maxNumber = this.round5(maxNumberOfStaticPrevious + maxNumberOfStatic / 3)
            }
        },
        handleDataTrendingWeek(statistic, statisticPrevious, listDateRange) {
            let rawData = [statistic, statisticPrevious] 
            this.dataGateTrending = []
            let dataGateTrending = []
            for (let i = 0; i < rawData.length; i++) {
                let line = {
                    color: this.listColor[i],
                    width: 2,
                }
                if (this.legendName[i] == this.$t("dashboard.ThePreviousSevenDays")) {
                    line = {
                        color: this.listColor[i],
                        width: 2,
                        dashStyle: "dash"
                    }
                }

                let pointData = []
                for (let z = 0; z < rawData[i].length; z++) {
                    pointData.push([listDateRange[z], rawData[i][z]])
                }
                let dataOfOneGate = {
                    name: this.legendName[i],
                    defaultPoint: {
                        marker: {
                            outline: {
                                width: 1,
                                color: 'white'
                            },
                            fill: this.listColor[i],
                            type: 'circle',
                            visible: true,
                            size: 12
                        }
                    },
                    line: line,
                    points: pointData
                }
                dataGateTrending.push(dataOfOneGate)
            }
            this.getMaxNumber(rawData[0], rawData[1])
            this.dataGateTrending = dataGateTrending
        },
        handleDataTrendingDay(statistic, statisticPrevious) {
            let rawData = [statistic, statisticPrevious] 
            this.dataGateTrending = []
            let dataGateTrending = []
            let hour = [
                "1/11/2020",
                "1/12/2020",
                "1/13/2020",
                "1/14/2020",
                "1/15/2020",
                "1/16/2020",
                "1/17/2020",
                "1/18/2020",
                "1/19/2020",
                "1/20/2020",
                "1/21/2020",
                "1/22/2020",
                "1/23/2020",
                "1/24/2020",
                "1/25/2020",
                "1/26/2020",
                "1/27/2020"
            ];
            for (let i = 0; i < rawData.length; i++) {
                let line = {
                    color: this.listColor[i],
                    width: 2,
                }
                if (this.legendName[i] == this.$t("Report.Yesterday")) {
                    line = {
                        color: this.listColor[i],
                        width: 2,
                        dashStyle: "dash"
                    }
                }
                
                let pointData = []
                for (let z = 0; z < rawData[i].length; z++) {
                    pointData.push([hour[z], rawData[i][z]])
                }
                let dataOfOneGate = {
                    name: this.legendName[i],
                    defaultPoint: {
                        marker: {
                            outline: {
                                width: 1,
                                color: 'white'
                            },
                            fill: this.listColor[i],
                            type: 'circle',
                            visible: true,
                            size: 12
                        }
                    },
                    line: line,
                    points: pointData
                }
                dataGateTrending.push(dataOfOneGate)
                
            }
            this.getMaxNumber(rawData[0], rawData[1])
            this.dataGateTrending = dataGateTrending
        },
        round5(x) {
            return Math.ceil(x / 50) * 50;
        },
        getAPITrendingDashboard(option) {
            this.dateStartToday = moment().startOf("day").format("x") / 1000
            if (option == "sevenDaysAgo") {
                let dateRange = {
                    "dateStart": this.dateStartToday - 86400*6,
                    "dateEnd": this.dateStartToday
                }
                let dateRangeComapare = {
                    "dateStart": this.dateStartToday - 86400*13,
                    "dateEnd": this.dateStartToday - 86400*7
                }

                this.legendName = [this.$t("Report.TheLastSevenDays"), this.$t("dashboard.ThePreviousSevenDays")]
                let listDateRange = []
                for (let i = 0; i < 7; i++) {
                    listDateRange.push(moment.unix(this.dateStartToday - 86400*i).format("M/DD/YYYY"))
                }
                trendingDashboardRetail.get(dateRange, dateRangeComapare, this.choosenGroupID).then(response => {
                    this.handleDataTrendingWeek(response.data.statistic.reverse(), response.data.statisticPrevious.reverse(), listDateRange)
                })
            } else {
                let dateRange = {
                    "dateStart": this.dateStartToday,
                    "dateEnd": this.dateStartToday
                }
                let dateRangeComapare = {
                    "dateStart": this.dateStartToday - 86400,
                    "dateEnd": this.dateStartToday - 86400
                }
                let dateEnd = 
                this.legendName = [this.$t("Report.Today"), this.$t("Report.Yesterday")]
                trendingDashboardRetail.get(dateRange, dateRangeComapare, this.choosenGroupID).then(response => {
                    this.handleDataTrendingDay(response.data.statistic, response.data.statisticPrevious)
                })
            }
            
        },
        

        clickChoosingLayout() {
            this.$refs["wrapOptionLayout"].style.display = "block";
            this.$refs["wrapOptionLayout"].style.visibility = "visible";
        },
        handleClickDropDownChooseHeatmapType() {
            this.$refs["wrapOptionChooseHeatmapType"].style.display = "block";
            this.$refs["wrapOptionChooseHeatmapType"].style.visibility = "visible";
        },
        chooseLineChartOption() {
            this.$refs["wrapDayOption"].style.position = "absolute";
            this.$refs["wrapDayOption"].style.display = "block";
            this.$refs["wrapDayOption"].style.visibility = "visible";
        },
        handleClickOutSideLineChart() {
            this.$refs["wrapDayOption"].style.display = "none";
            this.$refs["wrapDayOption"].style.visibility = "hidden";
        },
        changeOptionLineChart(option) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideLineChart();
            }, 100);
            this.linechartOption = this.$t("Report." + option);
            if (this.linechartOption == this.$t("Report.TheLastSevenDays")) {
                this.getAPITrendingDashboard("sevenDaysAgo")
            } else {
                this.getAPITrendingDashboard("today")
            }
        }
        
    },
    data() {
        let self = this
        return {
            linechartOption: self.$t("Report.Today"),
            chartDataFunnel: [],
            formatDateToday: moment().format("DD/MM/YYYY"),
            listGroup: [],
            listDataCard: [],
            dateStartToday: moment().startOf("day").format("x") / 1000,
            dataGateTrending: [],
            maxNumber: 0,
            listColor: ["#D81F41", "#104579"],
            legendName: [self.$t("Report.Today"), self.$t("Report.Yesterday")],
            hourTransform: [],
            choosenGroupName: self.$t("dashboard.SumOfAllShop"),
            choosenGroupNameRaw: "",
            choosenGroupID: "",
            currentTime: moment().format("HH:mm:ss"),
            dayText: " ngày ",
            chartLeftMargin: "-50"
        }
    },
};
</script>

<style lang="scss">
.mt-40 {
    margin-top: 40px;
}

.mt-24 {
    margin-top: 24px;
}

.ml-8 {
    margin-left: 8px;
}

.mr-8 {
    margin-right: 8px;
}

.mt-12 {
    margin-top: 12px;
}

.pd-24 {
    padding: 24px;
}

.pd-16 {
    padding: 16px;
}

.mt-4px {
    margin-top: 4px;
}

.mt-2px {
    margin-top: 2px;
}

.mt-32 {
    margin-top: 32px;
}
.mr-16 {
    margin-right: 16px;
}
.ml-16 {
    margin-left: 16px;
}
.ml-32 {
    margin-left: 32px;
}
.ml-auto {
    margin-left: auto;
}

.detail-report-main-container {
    overflow: hidden;
    font-family: "Roboto";
    .TitleDashboard {
        font-size: 28px;
        font-weight: medium;
        font-family: "Roboto";
        line-height: 32px;
        color: #1F1F1F !important;
    }
    .current-time {
        font-size: 14px;
        font-weight: 400;
        display: inline;
        text-transform: lowercase;
        font-family: "Roboto";
        line-height: 20px;
    }
    .detail-select-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .Detail-search-noti-info {
            display: flex;
            .BTN-icon {
                height: 56px;
                width: 56px;
                margin: 0 !important;
                background-color: transparent !important;
                box-shadow: none;

                .md-ripple {
                    padding: 6px 12px !important;
                }
                .md-icon {
                    color: #000 !important;
                    height: 24px !important;
                    width: 24px !important;
                }
            }
            .BTN-icon:hover {
                background-color: #fff !important;
            }
            .avatar {
                margin-left: 32px;
                height: 56px !important;
                .md-ripple {
                    padding: 16px 6px;
                }
            }
        }
        .select-area {
            width: 20%;
            margin-left: auto;
            margin-top: 0;
        }
    }
    .ChooseStore {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        border: 1px solid #8C8C8C;
        border-radius: 8px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #434343;
        cursor: pointer;
        width: fit-content;
        position: relative;
        .material-icons {
            color: #8C8C8C;
        }
        .wrapOptionTime {
            overflow: auto;
            background: #ffffff;
            box-sizing: border-box;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            position: absolute;
            top: 58px;
            right: 0;
            z-index: 9999;
            visibility: hidden;
            max-height: 312px;
            overflow: auto;
            width: 250px;
            left: 5px;
            .Option {
                padding: 16px;
                font-family: "Roboto";
                font-size: 14px;
                line-height: 150%;
                color: #272833;
                min-width: 180px;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                    background-color: #f6f9ff;
                }
            }
        }
        &:hover {
            .wrapOptionTime {
                visibility: visible;
            }
        }
    }
    .WrapOverView {
            display: grid;
            width: 100%;
            grid-template-columns: 516px 1fr;
            row-gap: 0;
            column-gap: 24px;
            height: 376px;
        .FunnelChart {
            Height: 376px;
            position: relative;
            box-sizing: border-box;
            overflow: hidden;
            .wrapChartFunnel {
                position: absolute;
                top: -20px;
                left: -115px;
            }
            .HiddenTrial {
                width: 50px;
                height: 50px;
                background-color: #F8FBFF;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
            }
        }
        .ValueOverview {
            display: grid;
            height: 100%;
            grid-template-columns: repeat(2,1fr);
            row-gap: 56px;
            column-gap: 24px;
            padding: 0 24PX;
        }
    }
    .WrapChartTable {
        display: grid;
        height: 532px;
        width: 100%;
        grid-template-columns: 516px 1fr;
        row-gap: 0;
        column-gap: 24px;
        
        .LineChart {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
            height: 100%;
            padding: 24px;
            .HeaderLineChart {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 20px;
                .TitleLineChart {
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #1F1F1F;
                }
                .Btn-LineChart {
                    cursor: pointer;
                    padding: 8px 16px;
                    border: 1px solid #8C8C8C;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #434343;
                    margin-top: -8px;
                    position: relative;
                    .wrapOption {
                        overflow: auto;
                        background: #ffffff;
                        box-sizing: border-box;
                        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
                        border-radius: 8px;
                        position: absolute;
                        width: 212px !important;
                        height: 92px !important;
                        top: 50px;
                        z-index: 9999;
                        // visibility: hidden;
                        max-height: 312px;
                        overflow: auto;
                        .Option {
                            padding: 12px;
                            font-family: "Roboto";
                            font-size: 14px;
                            width: 196px !important;
                            height: 36px !important;
                            line-height: 150%;
                            color: #333333;
                            min-width: 180px;
                            font-weight: 400;
                            cursor: pointer;
                            &:hover {
                                background-color: #f6f9ff;
                            }
                        }
                    }
                }
            }
            .Bottom {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .today {
                    display: flex;
                    align-items: center;
                    margin-right: 40px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #1F1F1F;
                    .ColorToday {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: #D81F41;
                    }
                }
                .yesterday {
                    display: flex;
                    align-items: center;
                    font-family: 'Roboto';
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #1F1F1F;
                    .ColorYesterday {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: #104579;
                    }
                }
            }
        }
        .HeatmapTable {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
            height: 100%;
            padding: 24px;
            position: relative;
        }
    }
    .WrapBigTable {
        .title {
            font-family: 'Roboto';
            font-weight: 500; 
            font-size: 20px;
            line-height: 24px;
            color: #1F1F1F;
            margin-left: 24px;
        }
    }
}

</style>